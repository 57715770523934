import React from "react";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import Container from "../components/container";
import Seo from "../components/seo";
import Layout from "../containers/layout";

const IndexPage = (props) => {
  const { posts, settings } = props.pageContext;

  return (
    <Layout
      logo={settings.logo}
      header={settings.header}
      footer={settings.footer}
    >
      <Seo
        title={settings.title}
        description={settings.description}
        keywords={settings.keywords}
      />
      <Container>
        {posts.length > 0 && (
          <BlogPostPreviewList
            title="Latest blog posts"
            posts={posts}
            browseMoreHref="/archive"
          />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
