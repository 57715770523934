import * as styles from "./style.module.css";
import { getBlogUrl } from "../../lib/helpers";
import Link from "../../helpers/link";
import React from "react";
import { format } from "date-fns";
import Image from "../image";
import RichText from "../rich-text";
import { getAspectRatio } from "../../lib/helpers";

const BlogPostPreview = (props) => {
  const { title, excerpt, mainImage, publishedAt, slug } = props;
  return (
    <Link
      to={getBlogUrl(publishedAt, slug.current)}
    >
      <div className="flex justify-center">
        {mainImage && mainImage.asset && (
          <Image image={mainImage} className={getAspectRatio(mainImage?.ratio)} />
        )}
      </div>
      <div className={styles.text}>
        <h2 className="mt-4">{title}</h2>
        {excerpt && excerpt?.data?.length > 0 && (
          <div className={styles.excerpt}>
            <RichText value={excerpt.data} />
          </div>
        )}
        <div className={styles.date}>
          {format(new Date(publishedAt), "MMMM Mo, yyyy")}
        </div>
      </div>
    </Link>
  );
}

export default BlogPostPreview;
