import React from "react";
import Layout from "../components/layout";
import "react-sliding-pane/dist/react-sliding-pane.css";

const LayoutContainer = (props) => {
  const { logo, header, footer } = props;

  return (
    <Layout
      {...props}
      logo={logo}
      header={header}
      footer={footer}
    />
  );
}

export default LayoutContainer;
