import * as styles from "./style.module.css";
import BlogPostPreview from "../blog-post-preview";
import Link from "../../helpers/link";
import React from "react";

const BlogPostPreviewGrid = ({ title, posts, browseMoreHref }) => {
  return (
    <div className={styles.root}>
      {title && <h2 className={styles.headline}>{title}</h2>}
      <ul className={styles.grid}>
        {posts.length > 0 &&
          posts.map((node, i) => (
            <li key={i}>
              <BlogPostPreview {...node} isInList />
            </li>
          ))}
      </ul>
      {browseMoreHref && (
        <div className="text-white flex justify-center" >
          <Link className="btn-secondary w-4/5 md:w-1/3 lg:w-1/3" to={browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  );
}

export default BlogPostPreviewGrid;
